import {LoggerColorScheme} from '../types/logger-color-scheme';

export const DEFAULT_COLOR_SCHEME: LoggerColorScheme = [
  'purple',
  'teal',
  'gray',
  'gray',
  'red',
  'red',
  'red'
];
